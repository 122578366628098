@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@layer components {
  .max-container {
    max-width: 1440px;
    margin: 0 auto;
  }
  .FadeEffect {
    background: rgba(255, 255, 255, 0.54);
  }
  .Background {
    background-image: linear-gradient(
      to right,
      #ffe1bc,
      #ffe4c7,
      #ffe7d2,
      #ffebdc,
      #ffefe6,
      #ffeee7,
      #ffede7,
      #ffece8,
      #ffe5e1,
      #ffdedb,
      #ffd6d5,
      #ffcfd1
    );
  }
  .gradient-border {
    background-image: linear-gradient(115deg, purple, red, orange, yellow);
  }
}

@layer utilities {
  .padding {
    @apply sm:px-16 px-8 sm:py-24 py-12;
  }

  .padding-x {
    @apply sm:px-16 px-8;
  }

  .padding-y {
    @apply sm:py-24 py-12;
  }

  .padding-l {
    @apply sm:pl-16 pl-8;
  }

  .padding-r {
    @apply sm:pr-16 pr-8;
  }

  .padding-t {
    @apply sm:pt-24 pt-12;
  }

  .padding-b {
    @apply sm:pb-24 pb-12;
  }

  .info-text {
    @apply font-raleway text-gray  text-base leading-7;
  }
  .title-text {
    @apply font-lexendgiga text-3xl  max-sm:text-[18px] max-sm:leading-[62px] font-bold;
  }

  .background-logo {
    @apply bg-syndata-logo bg-center bg-no-repeat  bg-contain;
  }
  .active {
    @apply bg-gradient-to-r from-yellow-600 to-red-600 bg-clip-text text-transparent;
  }
  .nav-background {
    @apply bg-transparant-orange fixed;
  }
  .gradient-text{
    @apply bg-gradient-to-b  from-semi-orange to-red-600  bg-clip-text text-transparent;
  }
  .gradient-background{
    @apply bg-gradient-to-bl from-semi-orange to-orange;
  }
  .flex-view{
    @apply flex items-center justify-center justify-items-center;
  }
}
